@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.active-navbar {
  color: #712e86 !important;
  cursor: pointer !important;
}

.left-right-10percent-margin {
  margin-left: 10%;
  margin-right: 10%;
}

.big-title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.2em;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.two-column-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  gap: 20px; /* Gap between grid items */
  margin-bottom: 20px;
}

.purple-background {
  background-color: #703895;
}

@media (max-width: 768px) {
  .big-title {
    font-size: 15px;
    top: 25%;
  }
}

.title-container {
  position: relative;
  text-align: center;
}

.navbar {
  color: #fff;
  padding: 10px 0;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 300px;
  margin-right: 105px;
}

.right-section {
  display: flex;
  flex-direction: column;
}

.top-menu {
  text-align: right;
}

.menu {
  margin-top: 40px;
}

.top-menu a {
  text-decoration: none;
  color: #712e86;
  font-weight: 600;
  margin-right: 40px;
}

.menu a {
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  margin-right: 35px;
}

.menu a:hover,
.top-menu a:hover {
  color: #712e86;
}

.home-video {
  height: 500px;
}

.home-video-container {
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 50px;
}

/* Add this to your existing CSS */
@media (max-width: 768px) {
  .menu {
    display: none;
    margin: 0;
  }

  .top-menu {
    display: none;
  }

  .logo img {
    width: 250px;
    margin-right: 0;
  }

  .logo img {
    width: 250px;
    /* Adjust the width as needed */
    margin-right: 0;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 180px;
    left: 0;
    width: 100%;
    background-color: #fff; /* Adjust as needed */
    z-index: 1;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .mobile-menu a {
    color: #000000;
    margin: 10px 20px;
  }

  .hamburger-menu {
    cursor: pointer;
    padding: 10px;
    width: 30px;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
    margin: 5px 0;
  }

  .home-video {
    height: 300px;
  }

  .home-video-container {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 0px;
  }
}

/* ABOUT */
.about-container {
  background-color: #703895;
  display: flex;
  margin: 0 auto;
  /* flex-wrap: wrap; */
}

.about-image {
  flex: 1;
}

.about-image img {
  max-width: 500px;
  height: auto;
}

.image-contain {
  object-fit: contain;
  height: auto;
}

.width-50-100 {
  width: 100%;
}

.text-white-bg {
  color: white;
  padding: 20px;
}

.about-text {
  flex: 2;
  color: white;
  padding: 20px;
}

.text-background p {
  font-size: 16px;
  line-height: 1.5;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content {
  background-color: #eccfff;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    width: 100%;
  }

  .about-image {
    margin: 0;
  }
  .about-image img {
    width: 100%;
    max-width: none;
  }
}

.strategy-container {
  border: 1px solid #703895;
  border-radius: 20px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 25px;
}

.strategy-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* Center the items horizontally */
}

.strategy-item {
  flex: 1;
  margin: 10px;
  text-align: center;
  max-width: 300px;
  /* Limit the width of each item */
}

.strategy-image img {
  max-width: 100%;
}

/* Add media query for mobile devices */
@media (max-width: 768px) {
  .strategy-container {
    width: 100%;
    padding: 0;
  }

  .strategy-item {
    flex: 1;
    margin: 5px;
    max-width: 100%;
  }

  .strategy-section {
    display: contents;
  }
}

/* PARENTS */

.parents-container {
  /* background-color: #703895; */
  display: flex;
  margin: 0 auto;
  padding: 15px;
}

.parents-border {
  margin: 0 auto;
  width: 60%;
  border: 2px black solid;
  border-radius: 20px;
}

.parent-text {
  flex: 2;
  color: rgb(0, 0, 0);
}

.parents-image {
  flex: 1;
  margin-right: 20px;
}

.parents-image img {
  max-width: 450px;
  height: auto;
}

.parents-text {
  flex: 2;
  color: white;
}

/* Define a CSS class for mobile devices */
@media (max-width: 768px) {
  .parents-container {
    flex-direction: column;
    /* Stack items vertically on mobile */
  }

  .parents-container .parents-text {
    order: 1;
    /* Display the text before the image on mobile */
  }

  .parents-border {
    width: 95%;
  }

  .parents-image {
    margin: auto;
  }

  .parents-image img {
    width: 100%;
    max-width: none;
  }
}

.small-parents-container {
  /* background-color: #703895; */
  /* display: flex; */
  margin: 0 auto;
  /* padding: 15px; */
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.small-parents-border {
  margin: 0 auto;
  width: 60%;
  border: 2px black solid;
  border-radius: 20px;
}

.parent-text {
  flex: 2;
  color: rgb(0, 0, 0);
}

.small-parents-image {
  flex: 1;
  /* margin-right: 20px; */
  border-radius: 10px;
}

.small-parents-image img {
  max-width: 450px;
  height: auto;
  border-radius: 10px;
}

.small-parents-text {
  flex: 2;
  color: white;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.other-article {
  display: flex;
  width: 65%;
  margin: 25px auto auto;
}

@media (max-width: 768px) {
  .other-article {
    display: block;
  }
}

/* Define a CSS class for mobile devices */
@media (max-width: 768px) {
  .small-parents-container {
    flex-direction: column;
    /* Stack items vertically on mobile */
  }

  .small-parents-container .small-parents-text {
    order: 1;
    /* Display the text before the image on mobile */
  }

  .small-parents-border {
    width: 95%;
  }

  .small-parents-image {
    margin: auto;
  }

  .small-parents-image img {
    width: 100%;
    max-width: none;
  }
}

/* #11BD30 */

/* EXPERT PANEL */

.panel-container {
  display: flex;
  justify-content: center;
}

.panel-small-container {
  padding: 25px;
  margin: 10px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

@media (max-width: 768px) {
  .panel-container {
    flex-wrap: wrap;
  }
}

/* CONTACT */
.contact-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding-left: 15%;
  padding-right: 15%;
}

.contact-form {
  flex: 1;
  padding: 20px;
  border-radius: 5px;
}

.contact-form h2 {
  text-align: center;
}

.form-group {
  margin: 10px 0;
}

.name-fields {
  display: flex;
  justify-content: space-between;
}

.name-input {
  flex: 1;
  margin-right: 10px;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

button {
  background-color: #732e89;
  color: #fff;
  padding: 14px 35px;
  border-radius: 50px !important;
  border: none;
  cursor: pointer;
}

.sm-rounded-button {
  border-radius: 10px !important;
}

button:hover {
  background-color: #0056b3;
}

.google-map {
  flex: 1;
  padding-left: 20px;
  border-radius: 5px;
  text-align: left;
}

.contact-info {
  margin-top: 20px;
  text-align: left;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 0;
  }

  .contact-form {
    margin-bottom: 20px;
  }
}

/* Moduke */

.white-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 80%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* Base styles for cards */
.card-module {
  border: 2.5px solid #732e89;
  border-radius: 13px;
  padding: 15px;
  width: 30%;
  position: relative;
  height: 330px;
}

.card-icon img {
  width: 40px;
  height: 40px;
  display: block;
  margin-top: 10px;
}

.card-title {
  font-size: 20px;
  position: relative;
  border-bottom: 1.3px solid #732e89;
  max-width: 70%;
  text-align: left;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.card-description {
  font-size: 14px;
  color: #000000;
}

.card-action button {
  display: block;
  background-color: #732e89;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.card-action button:hover {
  background-color: #0050a7;
}

/* Responsive styles */
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.materials-img {
  width: 30%;
}

@media (max-width: 768px) {
  .card-module {
    width: 60%;
    /* Adjust the width for smaller screens */
  }

  .cards-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .materials-img {
    width: 350px;
  }
}

@media (max-width: 480px) {
  .card-module {
    width: 100%;
    /* Full width for the smallest screens */
  }
}

.card-content {
  padding-right: 30px;
}

/* Footer */

.footer {
  color: #000000;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 10%;
  padding-right: 10%;
}

.error-button {
  border: 1px solid #703895;
  background: #fff;
  color: #703895;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 129px;
  border-width: 1px;
  width: 50%;
}

@media (max-width: 768px) {
  .footer {
    display: none;
  }
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-right a {
  color: #000000;
  margin: 0 10px;
  text-decoration: none;
}

.footer-right a:hover {
  text-decoration: underline;
}

/* Login */
.login-text {
  color: #712e86;
  font-weight: 600;
  font-size: 35px;
}

/* Clinic */

.clinic-list {
  flex: 2;
  /* padding: 20px; */
}

.clinic {
  margin-bottom: 20px;
  border: #eef3f6 1px solid;
  padding: 15px;
}

.clinic-list-2 {
  flex: 2 1;
  padding: 20px;
  width: 50%;
  /* display: block; */
  display: flex;
  flex-wrap: wrap;
}

.clinic-list-3 {
  flex: 2 1;
  /* padding: 20px; */
  width: 50%;
  /* display: block; */
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.clinic-2 {
  border: #eef3f6 1px solid;
  padding: 15px;
  width: 40%;
}

.clinic:hover {
  border: #aec0cc 1px solid;
}

.clinic-3:hover {
  border: #aec0cc 1px solid;
}

.square-button {
  color: #fff;
  background-color: #712e86;
  border-color: #712e86;
  padding: 10px 20px;
  /* Adjust padding to make the button square */
  border: none;
  cursor: pointer;
  border-radius: inherit;
}

.clinic-name {
  color: #0366d6;
  margin: 0;
}

.clinic-name:hover {
  color: #712e86;
  cursor: pointer;
}

.clinic-container {
  display: flex;
  margin: auto;
  width: 65%;
  margin-top: 25px;
}

.autocomplete-text {
  width: 450px;
  height: 40px;
  border-radius: 0;
}

@media (max-width: 768px) {
  .clinic-container {
    display: block;
  }

  .autocomplete-text {
    width: 200px;
  }
}

/* ABOUT */
.top-clinic-container {
  background-color: #703895;
  display: flex;
  margin: 0 auto;
  width: 65%;
  /* padding: 20px; */
}

.top-clinic-image {
  flex: 1;
  /* margin-right: 20px; */
}

.top-clinic-image img {
  max-width: 500px;
  height: auto;
}

.top-clinic-text {
  flex: 2;
  color: white;
  padding: 20px;
}

.text-background p {
  font-size: 16px;
  line-height: 1.5;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .top-clinic-container {
    flex-direction: column;
    width: 100%;
  }

  .top-clinic-image {
    margin: 0;
  }

  .top-clinic-image img {
    width: 100%;
    max-width: none;
  }
}

/* Ask expert */

.ask-expert-container {
  width: 50%;
  margin: auto;
  background: linear-gradient(180deg, #fbefff 0%, #fbefff 100%);
  border-radius: 10px;
  padding: 10px 50px 20px 50px;
}

.input-expert {
  background: #eccfff;
}

/* E-Learning components */

/* Video */
.video-container {
  padding: 20px;
}

.e-learning-container {
  width: 80%;
  margin: auto;
}

.button-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 5px 30px;
}

.content {
  display: flex;
  flex: 3 1;
}

@media (max-width: 768px) {
  .content {
    display: block;
  }
}

.sidebar {
  /* flex: 1; */
  padding: 50px 35px;
  background: linear-gradient(180deg, #742d89 0%, #9934b8 100%);
  border-radius: 20px;
  display: grid;
  color: white;
  min-width: 230px;
}

.sidebar h3 {
  margin-top: 0;
}

.video {
  flex: 3;
  /* padding: 20px; */
  padding-left: 10%;
}

@media (max-width: 768px) {
  .video {
    /* flex: 3; */
    /* padding: 20px; */
    padding-left: 0;
    margin-top: 20px;
  }
}

.video h3 {
  margin-top: 0;
}

iframe {
  width: 100%;
  height: 400px;
  border: none;
}

/* Assessment */

.assessment-container {
  background: linear-gradient(180deg, #fbefff 0%, #fbefff 100%);
  height: auto;
  border-radius: 20px;
  width: 100%;
}

ul.no-bullets {
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}

/* Certificate */

.certificate-container {
  background: linear-gradient(180deg, #fbefff 0%, #fbefff 100%);
  height: auto;
  border-radius: 20px;
}

/* Healthcare */

/* healthcare.css */

.video-iframe {
  width: 100%;
  height: 700px;
}

.video-title {
  color: #712e86;
}

.video-description {
  color: #712e86;
}

.feature-wrapper {
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px #712e86 solid;
  border-radius: 20px;
  padding: 20px;
}

.purple-border {
  border: 2px #712e86 solid;
  border-radius: 20px;
  padding: 20px;
}

.feature-title {
  color: #712e86;
}

.feature-image {
  max-width: 50%;
  height: auto;
}

/* CSS for mobile devices */
@media (max-width: 768px) {
  .feature-wrapper {
    flex-direction: column; /* Stack the title and image vertically on mobile */
    text-align: center;
    width: 85%;
  }

  .feature-title {
    margin-bottom: 20px; /* Add space between the title and image */
  }

  .feature-image {
    max-width: 100%;
  }
}

/* Download */

.download-container {
  display: flex;
  justify-content: center;
}

.download-small-container {
  padding: 25px;
  margin: 10px;
  /* Decreased margin for mobile devices */
  text-align: center;
  width: 90%;
  /* Make it 90% width on mobile devices */
  max-width: 225px;
  /* Limit the maximum width on smaller screens */
}

@media (max-width: 768px) {
  .download-container {
    flex-wrap: wrap;
  }
}

/* test home */

.bottom-border-input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d9d9d9; /* Add the desired border color here */
}

.register-border-input {
  border-radius: 30px !important;
}

/* ARTICLE  */

.article-image {
  background-color: var(--e-global-color-ed5a97b);
  border-radius: 18px 18px 18px 18px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}

.article-container {
  margin: auto;
  justify-content: center;
  /* text-align: center; */
  padding-right: 23%;
  padding-left: 23%;
}

th {
  border: 1px solid #dddddd;
  padding: 15px;
  text-align: left;
  font-weight: normal;
  font-size: 18px;
}

/* Set the width for each th element */
th:nth-child(1) {
  width: 1%;
}

th:nth-child(2) {
  width: 1%;
}

.additional-options {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out; /* You can adjust the animation duration and easing */
}

.additional-options.show {
  max-height: 500px; /* Set an appropriate max-height value */
  margin: auto;
  width: 65%;
  margin-top: 20px;
}

.additional-options.hide {
  max-height: 0;
  margin: auto;
  width: 65%;
}

.frame {
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 38px;
  justify-content: space-between;
  position: relative;
  width: 65%;
  margin: auto;
}

.frame .tabler-search {
  height: 32px;
  margin-left: -0.5px;
  position: relative;
  width: 191px;
}

.filter-border {
  border: 2px solid #712e86;
  padding: 5px 10px 5px 10px;
  color: #712e86;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
}

.filter-border-2 {
  border: 2px solid #712e86;
  padding: 5px 10px 1px 10px;
  color: #712e86;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  margin-top: -1rem;
}

@media (max-width: 768px) {
  .filter-border {
    font-size: 8px;
  }

  .filter-border-2 {
    display: none;
  }
}

.filter-border:hover {
  background-color: #531c6a;
  color: white;
  cursor: pointer;
}

.filter-border-2:hover {
  background-color: #531c6a;
  color: white;
  cursor: pointer;
}

.speciality-container,
.state-container {
  margin-right: 20px;
}

.speciality-container label,
.state-container label {
  font-weight: bold;
  margin-bottom: 5px;
}

.speciality-container div,
.state-container div {
  margin-bottom: 10px;
}

.icon-filter {
  fill: #712e86;
  margin-right: 10px;
}

.icon-filter:hover {
  fill: #ffffff;
  margin-right: 10px;
}

.icon-filter-2 {
  fill: #712e86;
  margin-top: -0.5rem;
}

.icon-filter-2:hover {
  fill: #ffffff;
}

/* HOME */

.two-home-container {
  display: flex;
  width: 65%;
  margin: auto;
  margin-top: 25px;
}

@media (max-width: 768px) {
  .two-home-container {
    width: 90%;
    display: block;
  }
}

/* src/ReadMe.css */
.pageflip {
  width: 100%;
  height: 500px; /* Adjust the height as needed */
  perspective: 1200px; /* Adjust the perspective if needed */
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

/* ReadMe.css */
.page.marginLeft40 {
  margin-left: 40%;
}

.markedContent {
  display: none !important;
}

.pdf-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
}

@media (max-width: 700px) {
  .two-column-container {
    grid-template-columns: 1fr;
  }

  .left-right-10percent-margin {
    margin-left: 0%;
    margin-right: 0%;
  }
}
