/* Style for the main container */
.frame-5 {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 20px;
}

/* Style for the left container */
.frame-6 {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.text-wrapper-4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.imfed-malaysia-was {
    line-height: 1.6;
}

/* Style for the right container */
.frame-7 {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.text-wrapper-4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Style for the IMFeD Principles section */
.div-wrapper {
    margin-top: 20px;
}

.frame-8 {
    display: flex;
    align-items: center;
}

.image-2 {
    width: 50px;
    /* Adjust the size as needed */
    height: auto;
    margin-right: 10px;
}

.text-wrapper-6 {
    font-weight: bold;
}

.text-wrapper-7 {
    line-height: 1.6;
}

/* Add a hover effect to the About Us section */
.overlap-group-wrapper:hover .text-wrapper-5 {
    color: #007bff;
    /* Change to your preferred hover color */
}

/* Style for the new container */
.frame-10 {
    display: flex;
    align-items: center;
    padding-left: 5%;
}

.image-3 {
    width: 45%;
    /* Image takes up half of the screen width */
    height: auto;
}

.group-2 {
    flex: 1;
    background-color: rgba(0, 140, 193, 1);
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style for the Guidance for Parents section */
.overlap-group-2 {
    display: flex;
    align-items: center;
}

.frame-11 {
    flex: 1;
}

.text-wrapper-8 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
    /* Text color on the blue background */
}

.should-you-be {
    line-height: 1.6;
    color: #fff;
    /* Text color on the blue background */
}

.group-3 {
    width: 225px;
    /* Adjust the size as needed */
    height: auto;
}

/* Style for the new container */
.frame-12 {
    display: flex;
    align-items: center;
    padding-right: 5%;
}

.group-4 {
    flex: 1;
    background-color: rgba(236, 0, 140, 1);
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.group-test {
    flex: 1;
    background-color: rgba(112, 56, 149, 1);
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style for the Exclusively for Healthcare Professional section */
.overlap-group-2 {
    display: flex;
    align-items: center;
}

.img-wrapper {
    width: 50%;
    /* Adjust the size as needed */
    margin-right: 20px;
}


.frame-13 {
    flex: 1;
}

.text-wrapper-9 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
    /* Text color on the blue background */
}

.lectures-by-experts {
    line-height: 1.6;
    color: #fff;
    /* Text color on the blue background */
}

.image-4 {
    width: 45%;
    /* Image takes up half of the screen width */
    height: auto;
}

.image-5 {
    width: 45%;
    /* Image takes up half of the screen width */
    height: auto;
}


.text-wrapper-4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.imfed-malaysia-was,
.text-wrapper-7,
.manage-optimise,
.should-you-be,
.lectures-by-experts {
    line-height: 1.6;
}

.div-wrapper {
    margin-top: 20px;
}

.frame-8 {
    display: flex;
    align-items: center;
}

.image-2 {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.text-wrapper-6 {
    font-weight: bold;
}

/* Add a hover effect to the About Us section */
.overlap-group-wrapper:hover .text-wrapper-5 {
    color: #007bff;
}

/* Styles for medium-sized screens */
@media (max-width: 1200px) {
    .frame-5 {
        flex-direction: column;
    }

    .frame-6,
    .frame-7 {
        width: 100%;
        margin-bottom: 20px;
    }
}

/* Styles for small screens */
@media (max-width: 768px) {

    .text-wrapper-4,
    .text-wrapper-8,
    .text-wrapper-9 {
        font-size: 20px;
    }

    .frame-10,
    .frame-12 {
        flex-direction: column;
    }

    .image-3,
    .image-4,
    .image-5 {
        width: 100%;
        height: auto;
    }

    .group-2,
    .group-4,
    .group-test {
        width: 100%;
    }
}