* {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.big-margin {
  padding-left: 25%;
  padding-right: 25%;
}

.coming-soon-container {
  height: 300px;
}

.coming-soon-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.coming-soon-grid-img {
  width: 100%;
  border-radius: 10px;
}

.coming-soon-material-cat-container {
  background-color: #7e2f96;
  color: white;
  max-height: 80px;
}

.coming-soon-material-cat {
  width: 77px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* for mobile */
@media only screen and (max-width: 600px) {
  .big-margin {
    padding-left: 5%;
    padding-right: 5%;
  }

  .coming-soon-grid {
    display: flex;
    flex-wrap: wrap;
  }
}

.video-page-container {
  position: relative;
  margin-left: 5%;
  width: 55vw;
}

.video-page-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
}

@media screen and (max-width: 600px) {
  .video-page-container {
    width: 300px;
  }
}
